<template>
    <div class="container_main">
        <div class="search_form">
            <Form class="ant-advanced-search-form" :form_data="form_data_seo" @handleSubmit="handleSubmit" @handleReset="handleReset" ref="form_seo"></Form>
        </div>
        <div class="list_main">
            <div class="list_left">
                <div class="left_main">
                    <div class="FieldSettingsBox">
						<div class="FieldSettingsBoxTitle ">
							<span class="show_type">产品类别</span>
							<span class='show_type_setting' @click='treeSelectItem("","add")'>新增类别</span>
						</div>
                        <div style="padding:10px 0;">
                            <treeList :subInfo="treeData" :expandedKeys="expandedKeys" :selectedKeys="selectedKeys" :opStatus="true" @select='treeSelectItem'></treeList>
                        </div>
					</div>
                </div>
            </div>
            <div class="list_right">
                <TableList :columns="columns" :get_table_list="get_table_list" :where="where" :slot_table_list="['operation','status']" ref="list" :rowKey="'id'" :style_mode="''" >
                    <template slot="left_btn">
                        <a-button @click="getAddSave('','add')" type="primary">新增产品</a-button>
                    </template>
                    <template slot="status" slot-scope="data">
                        <span v-if="data.record.status == 1" class="status_box" @click="getMaterialStatus(data.record)">启用</span>
                        <span v-else class="status_box" style="background:red;" @click="getMaterialStatus(data.record)">禁用</span>
                    </template>
                    <template slot="operation" slot-scope="data">
                        <a @click="getAddSave(data.record,'edit')">编辑</a>
                        <a-divider type="vertical" />
                        <a @click="getDelete(data.record)">删除</a>
                    </template>
                </TableList>
            </div>
        </div>

        <!-- 添加或编辑类型信息 -->
        <a-modal v-model:visible="materialShow" :title="materialFrom.id ? '编辑产品类别' : '新增产品类别'" @ok="materialOk">
            <a-form :label-col="labelColEdit" :wrapper-col="wrapperColEdit">
                <a-form-item label="上级类别">
                    <a-tree-select  :replaceFields="{children:'children', label:'title', value: 'id' }" v-model:value="materialFrom.pid" style="width: 100%" :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" placeholder="请选择" tree-default-expand-all allow-clear :tree-data="treeData">
                    </a-tree-select>
                </a-form-item>
                <a-form-item required name='name' label="类别名称" :rules="[{ required: true, message: '类别名称不能为空',tagger:'blur' }]">
                    <a-input v-model:value="materialFrom.name" placeholder="请输入类别名称" />
                </a-form-item>
                <a-form-item required label="所属分类">
                    <a-select v-model:value="materialFrom.type" style="width: 100%" :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" placeholder="请选择" allow-clear>
                        <a-select-option :value="item.value" v-for="(item,index) in materialClass" :key="index">{{ item.label }}</a-select-option>
                    </a-select>
                </a-form-item>
            </a-form>
        </a-modal>

        <!-- 添加或编辑产品 -->
        <a-modal v-model:visible="productShow" :title="productFrom.id ? '编辑产品信息' : '新增产品信息'" @ok="productOk">
            <a-form :label-col="labelColEdit" :wrapper-col="wrapperColEdit">
                <a-form-item required label="所属类别">
                    <a-tree-select  :replaceFields="{children:'children', label:'title', value: 'id' }" v-model:value="productFrom.cate_id" style="width: 100%" :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" placeholder="请选择" tree-default-expand-all allow-clear :tree-data="treeData">
                    </a-tree-select>
                </a-form-item>
                <a-form-item required name='name' label="产品名称" :rules="[{ required: true, message: '产品名称不能为空',tagger:'blur' }]">
                    <a-input v-model:value="productFrom.material_name" placeholder="请输入产品名称" />
                </a-form-item>
                <a-form-item required name='name' label="产品编号" :rules="[{ required: true, message: '产品编号不能为空',tagger:'blur' }]">
                    <a-input v-model:value="productFrom.material_code" placeholder="请输入产品编号" />
                </a-form-item>
            </a-form>
        </a-modal>

    </div>
</template>

<script>
import Form from "@/components/Form";
import TableList from "@/components/TableList";
import treeList from '../components/treeList.vue'
import {message,Modal} from 'ant-design-vue';
import {getMaterialCateList,saveMaterialCate,delMaterialCate,getMaterialList,saveMaterial,delMaterial,changeMaterialStatus,getCateSelct} from '@/api/qms.js'

const columns = [{
        title: "ID号",
        dataIndex: "id",
    },
    {
        title: "所属类别",
        dataIndex: "cate_name",
    },
    {
        title: "产品名称",
        dataIndex: "material_name",
    },
    {
        title: "产品编号",
        dataIndex: "material_code",
    },
    {
        title: "产品状态",
        dataIndex: "status",
        scopedSlots: {
            customRender: "status"
        }
    },
    {
        title: "创建时间",
        dataIndex: "create_time",
    },
    {
        title: "操作",
        scopedSlots: {
            customRender: "operation"
        }
    }
];

export default{
    components: {
        Form,
        TableList,
        treeList
    },
    data() {
        return {
            columns,
            form_data_seo: {
                ...this.$config.form_data_seo,
                list: [
                    {
                        type: "text",
                        name: "keyword",
                        title: "关键词",
                        placeholder: "请输入产品名称",
                        options: {}
                    }
                ],
            },

            labelColEdit: {
                span: 4
            },
            wrapperColEdit: {
                span: 18
            },

            expandedKeys: [],
			selectedKeys: [],
            treeData: [],
            materialShow:false,
            materialFrom:{
                id:'',
                pid:'',
                name:'',
                type:'',
            },
            materialClass:[],

            where:{
                cate_id:'',
                keyword:'',
                is_select:'-1'
            },
            get_table_list:getMaterialList,
            productShow:false,
            productFrom:{
                id:'',
                cate_id:'',
                material_name:'',
                material_code:'',
            },
            
        }
    },
    created() {
        this.getMaterialCateData()
        this.getCateSelctList()
    },
    methods: {
        getCateSelctList(){
            getCateSelct({}).then(res=>{
                this.materialClass = res.data.list
            })
        },
        getMaterialCateData(){
            getMaterialCateList({
                data:{}
            }).then(res=>{
                this.treeData = res.data.list
            })
        },
        handleReset() {
            this.where.keyword = ''
            this.where.cate_id = ''
            this.selectedKeys = []
            this.$refs.list.get_list()
        },
        handleSubmit(e) {
            this.where.keyword = e.values.keyword
            this.$refs.list.get_list()
        },
        treeSelectItem(info,type) {
            //选中数据
            if(type == 'title'){
                let data = []
                data.push(info.id)
                this.selectedKeys = data
                this.where.cate_id = info.id
				this.$refs.list.pagination.page=1
                this.$refs.list.get_list()
            }
            //展开或收起
            if(type == 'updown'){
                if(this.expandedKeys.indexOf(info.id) > -1){
                    let expandedData = this.expandedKeys.filter( item => {
                        return item != info.id
                    })
                    this.expandedKeys = expandedData
                }else{
                    this.expandedKeys.push(info.id)
                }
            }
            //新增
            if(type == 'add'){
                this.materialFrom = {}
                this.materialShow = true
            }
            //编辑数据
            if(type == 'edit'){
                this.materialFrom.id = info.id
                this.materialFrom.pid = info.pid > 0 ? info.pid : []
                this.materialFrom.name = info.title
                this.materialFrom.type = info.type > 0 ? info.type : undefined
                this.materialShow = true
            }
            //删除数据
            if(type == 'delete'){
                let that = this
                Modal.confirm({
                    title: '温馨提示',
                    content: '确定要删除产品类别【'+info.title+'】吗？',
                    okText: '确定',
                    cancelText: '取消',
                    onOk() {
                        delMaterialCate({
                            id:info.id
                        }).then(res=>{
                            message.success('删除成功');
                            that.getMaterialCateData()
                        })
                    }
                });
            }
        },
        materialOk(){
            saveMaterialCate({
                ...this.materialFrom
            }).then(res=>{
                this.materialShow = false
                message.success('保存成功');
                this.getMaterialCateData()
            })
        },

        getAddSave(info,type){
            //新增
            if(type == 'add'){
                this.productFrom = {}
                this.productShow = true
            }
            //编辑数据
            if(type == 'edit'){
                this.productFrom.id = info.id
                this.productFrom.cate_id = info.cate_id > 0 ? info.cate_id : []
                this.productFrom.material_name = info.material_name
                this.productFrom.material_code = info.material_code
                this.productShow = true
            }
        },

        productOk(){
            saveMaterial({
                ...this.productFrom
            }).then(res=>{
                this.productShow = false
                message.success('保存成功');
                this.$refs.list.get_list()
            })
        },

        getDelete(info){
            let that = this
            Modal.confirm({
                title: '温馨提示',
                content: '确定要删除产品【'+info.material_name+'】吗？',
                okText: '确定',
                cancelText: '取消',
                onOk() {
                    delMaterial({
                        id:info.id
                    }).then(res=>{
                        message.success('删除成功');
                        that.$refs.list.get_list()
                    })
                }
            });
        },

        getMaterialStatus(info){
            let text = info.status == 1 ? "禁用" : "启用"
            let that = this
            Modal.confirm({
                title: '温馨提示',
                content: '确定要'+text+'产品【'+info.material_name+'】吗？',
                okText: '确定',
                cancelText: '取消',
                onOk() {
                    changeMaterialStatus({
                        id:info.id
                    }).then(res=>{
                        message.success('操作成功');
                        that.$refs.list.get_list()
                    })
                }
            });
        },
        
    }
}
</script>

<style lang="less" scoped>
.container_main{
    padding:15px;
}

.search_form{
    padding:15px 15px 0px 15px;
    background:#ffffff;
}

.list_main{
    margin-top:15px;
    display:flex;
    background:#ffffff;

    .list_left{
        width:260px;

        .left_main{
            width:100%;
        }
    }

    .list_right{
        width:calc(100% - 260px);
        padding:15px;
    }
}

.FieldSettingsBox {
    border: none;
    border-right: 1px solid #eeeeee;
    margin-top: 12px;
    height: auto;
    padding-left:15px;
}

.FieldSettingsBoxTitle {
    display: flex;
    width: 100%;
    padding: 8px 12px 8px 0px;
    border-bottom: 1px solid #eeeeee;
    justify-content:space-between;
}

.show_type {
    border-left: 3px solid #0079FF;
    padding-left: 10px;
    font-weight:bold;
}

.show_type_setting {
    text-align: right;
    color:#397dee;
    cursor:pointer;
}

.status_box{
    background:#1890ff;
    padding:1px 5px 3px 5px;
    color:#ffffff;
    border-radius:2px;
    font-size:12px;
    cursor:pointer;
    display:inline-block;
    min-width:34px;
}

/deep/.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon {
    color: #666;
    font-size: 16px;
}

/deep/.ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon,
/deep/.ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon {
    color: #333;
    font-size: 16px;
}

/deep/.ant-tree-node-content-wrapper{
    width:calc(100% - 36px);
}

.tree_title_main{
    width:100%;
    position: relative;

    .title_op{
        display:flex;
        position: absolute;
        top:0;
        right:0;
        background:red;
    }
}

</style>
